import logo from "./logo.svg";
// import './App.css';
import Game from "./components/game/Game"; // uses above component in same directory

function App() {
  return (
    // <div style={{width: "1000px", height:"1000px"}}>
      <Game />
    // </div>
  );
}

export default App;
