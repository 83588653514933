import React, { useState, useCallback, useEffect } from "react";
import HavokPhysics from "@babylonjs/havok";
import Shaiba_logo from "../../images/shaiba.png";
import Shaiba_count from "../../images/shaiba_count.png";
import Classic_goal from "../../images/classic_goal.png";
// import Goal_back7 from "../../images/goal_back7.png";
import Goal_back10 from "../../images/goal_back10.png";
import Goal_back11 from "../../images/goal_back11.png";
import Goal_back12 from "../../images/goal_back12.png";
import Back_instructions from "../../images/back_instruction.png";
import Back_start_blacked from "../../images/back_start_blacked.png";
import Start_button from "../../images/start_button.png";
import Flowers from "../../images/flowers.png";

import Loading_back from "../../images/loading_back.png";
import Loading_text from "../../images/loading_text.png";
import Pause_button from "../../images/pause_button.png";

import Best_score_back from "../../images/best_score_back.png";
// import debounce from 'debounce';

// import Back_pause_text from "../../images/pause_back_text.png";
import Continue_button from "../../images/continue_button.png";
import Game_over_back from "../../images/game_over_back.png";

import Save_result from "../../images/save_result.png";
import Replay from "../../images/replay.png";

import Shaiba_plus from "../../images/shaiba_plus.png";

import {
  DirectionalLight,
  FreeCamera,
  Vector3,
  HemisphericLight,
  MeshBuilder,
  ArcRotateCamera,
  SceneLoader,
  GlowLayer,
  Color3,
  StandardMaterial,
  DefaultRenderingPipeline,
  ColorCurves,
  DepthOfFieldEffectBlurLevel,
  ImageProcessingPostProcess,
  Texture,
  CubeTexture,
  UtilityLayerRenderer,
  HavokPlugin,
  PhysicsAggregate,
  PhysicsShapeType,
  Color4,
  PhysicsShapeMesh,
  PhysicsViewer,
  Mesh,
  ExecuteCodeAction,
  ActionManager,
  VertexData,
  Quaternion,
  VertexBuffer,
} from "@babylonjs/core";
import "@babylonjs/loaders";
import {
  AdvancedDynamicTexture,
  StackPanel,
  Control,
  Checkbox,
  TextBlock,
  Slider,
  ColorPicker,
  Image,
} from "@babylonjs/gui";
// import HockeyGameModel from

import SceneComponent from "./SceneComponent"; // uses above component in same directory
// import SceneComponent from 'babylonjs-hook'; // if you install 'babylonjs-hook' NPM.
// import "./App.css";
import "./Game.css";
import axios from "axios";
import { sha256 } from "js-sha256";
import webapp_launcher from "../../utils/telegram-web-app";
let flag_downed = false;

let plane_miss;
let attacker_container;
let box;
let shaiba;
let vratar;
let player;
let sh_collider;
let sh_collider2;
let attacker;
let speed = 0; // Начальная скорость
let acceleration_global = 0;
let player_kicked = false;
let klushka_down = false;
let klushka;
let klushka_up = false;
let reboundAngle;
let zDirection = 1;
let xDirection = 1;
let timer = null;
let global_speed = 0;
let target_angle_player = 180;
let return_klushka = false;
let target_player_z = 0;
let global_disabled;
let shaiba_invisible = false;
let shaiba_count = 7;
let power = 0;
let hud_glb;

let scene_global = null;

let attackers_klushkas = [null, null, null];

let attackers = [];

let sh_collider_z_start = -1;

let shaiba_timer = null;
let game_started = false;

let global_loading_screen;

let timer_rounded = null;

let angles_player = {
  default: 180,
  pre_attack: 195,
};

let prevPosition;
let shaiba_velocity;

let paused = false;

/**
 * 1. Вратарь
10
2. Вратарь скорость+
10
3. Вратарь+защитник
10
4. Вратарь+защитник+скорость
20
5. Вратарь+2 защитника
20
6. Вратарь+2 защитника+скорость
25
7. Вратарь+3 защитника
35
8. Вратарь+3 защитника+скорость
50


 */
let scores_table = [
  //0
  {
    goal: 10, //ok
    shtanga: 5,
  },
  //1
  {
    goal: 10, //ok
    shtanga: 5,
  },
  //2
  {
    goal: 10, //ok
    shtanga: 5,
  },
  //3
  {
    goal: 20, //ok
    shtanga: 10,
  },
  //4
  {
    goal: 20, //ok
    shtanga: 10,
  },
  //5
  {
    goal: 25, //ok
    shtanga: 15,
  },
  //6
  {
    goal: 35, //ok
    shtanga: 35,
  },
  //7
  {
    goal: 50, //ok
    shtanga: 15,
  },
];

/**
 * 1. Вратарь
2. Вратарь, более сложные и быстрые движения
3. Вратарь+защитник
4. Вратарь+более сложные движения защитников и вратаря
5. Вратарь+2 защитника
6. Вратарь+2 защитника+более сложные движения защитников и вратаря
7. Вратарь+3 защитника
8. Вратарь+3 защитника+более сложные движения защитников и вратаря


1. Вратарь
2. Вратарь, скорость чуть выше
3. Вратарь + защитник (более статичный, пример как в шкоде, движение через какой-тио промежуток времени)
4. Вратарь + защитников (пережвижения чуть чаще)
5. Вратарь + 2 защитника (движения защитников более статичны)
6. Вратарь + 2 защитника (пережвижения чуть чаще)
7. Вратарь + 3 защитника (движения защитников более статичны)
8. Вратарь+3 защитника (пережвижения чуть чаще)

Вот можно +- на это ориентироваться

 */
const table_levels = [
  //0
  {
    vratar: {
      speed: 0.0006,
    },
    attackers: {
      count: 0,
      speed: 0.8,
      timer: 2000,
    },
  },
  //1
  {
    vratar: {
      speed: 0.0009,
    },
    attackers: {
      count: 0,
      speed: 0.8,
      timer: 1500,
    },
  },
  //2
  //3. Вратарь + защитник (более статичный, пример как в шкоде, движение через какой-тио промежуток времени)

  {
    vratar: {
      speed: 0.0009,
    },
    attackers: {
      count: 1,
      speed: 0.3,
      timer: 2000,
    },
  },
  //3
  //4. Вратарь + защитников (пережвижения чуть чаще)
  {
    vratar: {
      speed: 0.0011,
    },
    attackers: {
      count: 1,
      speed: 0.5,
      timer: 1500,
    },
  },
  //4
  //5. Вратарь + 2 защитника (движения защитников более статичны)
  {
    vratar: {
      speed: 0.0009,
    },
    attackers: {
      count: 2,
      speed: 0.3,
      timer: 2000,
    },
  },
  //5
  //6. Вратарь + 2 защитника (пережвижения чуть чаще)
  {
    vratar: {
      speed: 0.0011,
    },
    attackers: {
      count: 2,
      speed: 0.5,
      timer: 1500,
    },
  },
  //6
  //7. Вратарь + 3 защитника (движения защитников более статичны)
  {
    vratar: {
      speed: 0.0009,
    },
    attackers: {
      count: 3,
      speed: 0.3,
      timer: 2000,
    },
  },

  //7
  //8. Вратарь+3 защитника (пережвижения чуть чаще)
  {
    vratar: {
      speed: 0.0018,
    },
    attackers: {
      count: 3,
      speed: 0.8,
      timer: 1000,
    },
  },
];

let level = 0;
const goals_by_level = 5;
let score = 0;
let goals = 0;

const onSceneReady =
  ({
    setLoading_back_visible,
    setDisabled,
    setGoals_,
    setPaused_,
    setShtanga_,
    setPower,
    setCountShaibas_,
  }) =>
  async (scene) => {
    scene_global = scene;
    // window.onblur = function () {
    //   if (game_started) {
    //     setPaused_(true);
    //   }
    // };
    // (function() {
    //   var hidden = "hidden";

    //   // если API доступно :
    //   if (hidden in document)
    //     document.addEventListener("visibilitychange", onchange);
    //   else if ((hidden = "mozHidden") in document)
    //     document.addEventListener("mozvisibilitychange", onchange);
    //   else if ((hidden = "webkitHidden") in document)
    //     document.addEventListener("webkitvisibilitychange", onchange);
    //   else if ((hidden = "msHidden") in document)
    //     document.addEventListener("msvisibilitychange", onchange);
    //   // Браузер IE 9 и ниже:
    //   else if ("onfocusin" in document)
    //     document.onfocusin = document.onfocusout = onchange;
    //   // Все прочие браузеры:
    //   else
    //     window.onpageshow = window.onpagehide
    //     = window.onfocus = window.onblur = onchange;

    //   function onchange (evt) {
    //     var v = "visible", h = "hidden",
    //         evtMap = {
    //           focus:v, focusin:v, pageshow:v, blur:h, focusout:h, pagehide:h
    //         };

    //     evt = evt || window.event;
    //     if (evt.type in evtMap){
    //       document.body.className = evtMap[evt.type];

    //     }
    //     else{
    //       document.body.className = this[hidden] ? "hidden" : "visible";
    //     }
    //     if(document.body.className === "hidden"){
    //       setPaused_(true);
    //     } else {
    //       setPaused_(false);
    //     }
    //   }

    //   // установить начальное состояние (но толко если браузер поддерживает Page Visibility API)
    //   if( document[hidden] !== undefined ){
    //     // onchange({type: document[hidden] ? "blur" : "focus"});
    //     console.log("api")
    //   }
    // })();
    setInterval(() => {
      if (document.hidden) {
        // Приостановка игры
        if (game_started) {
          setPaused_(true);
        }
      }
    }, 100);
    // document.addEventListener('visibilitychange', function() {
    //   if (document.visibilityState === 'hidden') {
    //     setPaused_(true);
    //   }
    // });
    // setInterval(() => {
    //   if (document.hidden) {
    //     // Приостановка игры
    //     setPaused_(true);

    //   }
    // }, 100);
    // document.addEventListener('pause', () => setPaused_(true), false);

    // window.onfocus = function () {
    //   if (game_started) {
    //     setPaused_(false);
    //   }
    // };
    // global_loading_screen = true
    // setLoading_back_visible(true)
    // scene.getEngine().displayLoadingUI()
    console.log("test_state", "fuck");
    // This creates and positions a free camera (non-mesh)
    const camera = new FreeCamera("camera1", new Vector3(-2.6, 2.9, 0), scene);
    // const camera = new FreeCamera("camera1", new Vector3(7, 7, 0), scene);

    camera.rotation = new Vector3(-20, 90, 0);
    // This targets the camera to scene origin
    camera.setTarget(new Vector3(6, 0, 0));
    camera.fov = 1.6;
    // const havokInstance = await HavokPhysics();
    // const havokPlugin = new HavokPlugin(true, havokInstance);
    // scene.enablePhysics(new Vector3(0, -9.8, 0), havokPlugin);

    scene.position = new Vector3(0, 0, 0);

    let scene_glb = await SceneLoader.ImportMeshAsync(
      "",
      "./models/",
      "Scene.glb",
      scene
    );

    // var plane = MeshBuilder.CreatePlane("plane");
    //
    // var miss_texture = AdvancedDynamicTexture.CreateForMesh(plane);

    // var img = new Image("img", "./textures/miss.png");
    // img.width = "100%";
    // img.height = "100%";

    // miss_texture.addControl(img);

    // newMeshes[0].position.y = 0;
    // newMeshes[0].scaling = new Vector3(1, 1, -1);
    // const res_mesh = Mesh.MergeMeshes(newMeshes);
    // newMeshes[0].position.y = 0;

    // scene_glb = newMeshes[1];
    const shtanga_right = scene.getNodeByName("shtangRight");

    const shtanga_left = scene.getNodeByName("shtangLeft");

    shtanga_left.isVisible = false;
    shtanga_right.isVisible = false;

    let timer_shtanga_left = null;
    let timer_shtanga_right = null;
    await SceneLoader.ImportMeshAsync("", "./models/", "fon1.glb", scene);
    //, (newMeshes) => {
    //   newMeshes[0].position.y = 0;
    //   newMeshes[0].scaling = new Vector3(1, 1, -1);
    // }
    //справа
    const cube1 = MeshBuilder.CreateCylinder(
      "box",
      { diameter: 0.2, height: 1 },
      scene
    );
    cube1.position = new Vector3(6.221, 0.5, -1.057);
    cube1.isVisible = false;

    const cube = MeshBuilder.CreateCylinder(
      "box",
      { diameter: 0.2, height: 1 },
      scene
    );
    cube.position = new Vector3(6.221, 0.5, 1.07219);
    cube.isVisible = false;
    // cube1.onCollideObservable.add((event) => {
    //   console.log('colisions')
    //   console.log(event);
    // })
    cube1.checkCollisions = true;

    const cube_vorota = MeshBuilder.CreateBox("vorota", { size: 1 }, scene);
    cube_vorota.position = new Vector3(6.25216, 0.321, 0);
    cube_vorota.scaling = new Vector3(0.04381, 1, 1.9);
    cube_vorota.isVisible = false;
    // var customMesh = new Mesh("custom", scene);

    // var positions = [
    //   -5, 2, -3, -7, -2, -3, -3, -2, -3, 5, 2, 3, 7, -2, 3, 3, -2, 3,
    // ];
    // var indices = [0, 1, 2, 3, 4, 5];

    // var vertexData = new VertexData();

    // vertexData.positions = positions;
    // vertexData.indices = indices;

    // vertexData.applyToMesh(customMesh);

    // customMesh.position = new Vector3(2, 0, -4);
    // customMesh.rotation = new Vector3(0, (90 * Math.PI) / 180, 0);
    // customMesh.isVisible = false;
    // var colors = customMesh.getVerticesData(VertexBuffer.ColorKind);
    // if (!colors) {
    //   colors = [];

    //   var positions = customMesh.getVerticesData(VertexBuffer.PositionKind);

    //   for (let p = 0; p < positions.length / 3; p++) {
    //     colors.push(Math.random(), Math.random(), Math.random(), 1);
    //   }
    // }

    // customMesh.setVerticesData(VertexBuffer.ColorKind, colors);

    cube_vorota.actionManager = new ActionManager(scene);

    shaiba = await SceneLoader.ImportMeshAsync(
      "",
      "./models/",
      "Shaiba.glb",
      scene
    );
    console.log(shaiba.meshes);
    shaiba.meshes[0].position = new Vector3(0, 0.2, -0.8);
    sh_collider = scene.getNodeByName("Cylinder");
    sh_collider2 = scene.getNodeByName("Cylinder.001");
    sh_collider2.setParent(sh_collider, true, true);
    sh_collider2.position = new Vector3(0, -0.0555, 0);
    // sh_collider2.isVisible = false;

    var plane_miss_glb = await SceneLoader.ImportMeshAsync(
      "",
      "./models/",
      "PROMAX.glb",
      scene
    );
    // const plane_miss = {
    //   isVisible: false,
    // }
    plane_miss = scene.getNodeByName("promax");
    plane_miss.setParent(sh_collider, true, true);
    plane_miss.position = new Vector3(1, 0.7, 0.2);
    plane_miss.scaling = new Vector3(1, 1, 1);
    plane_miss.rotation = new Vector3(0, 0, (24 * Math.PI) / 180);
    // plane_miss.material.alpha = 0.4;
    plane_miss.isVisible = false;
    // const miss_light = new DirectionalLight("DirectionalLight", new Vector3(0, -1, 0), scene);
    // miss_light.setParent(plane_miss, true, true);

    // var gui = AdvancedDynamicTexture.CreateForMesh(plane_miss);

    // var img = new Image("img", "./textures/miss.png");
    // img.width = "100%";
    // img.height = "100%";
    // img.light
    // img.detectPointerOnOpaqueOnly = true;

    // const mat = new StandardMaterial("");
    // mat.diffuseTexture = new Texture(
    //   "https://assets.babylonjs.com/environments/tile1.jpg"
    // );
    // mat.emissiveColor = Color3.White();

    // img.onPointerClickObservable.add(() => {
    //     console.log('clicked');
    // })

    // gui.addControl(img);

    console.log(sh_collider.material);
    console.log("sh_collider", sh_collider);
    box = MeshBuilder.CreateCylinder(
      "box",
      { height: 0.074, diameter: 0.256 },
      scene
    );

    box.setParent(sh_collider, true, true);

    box.position = new Vector3(0, 0, 0);

    box.isVisible = false;
    // const anim1 = scene.getAnimationGroupByName("Kick");

    box.actionManager = new ActionManager(scene);
    // let i = 0;
    // attacker = await SceneLoader.ImportMeshAsync(
    //   "",
    //   "./models/",
    //   "AttackAnim.glb",
    //   scene
    // );
    // attacker.position = new Vector3(-4.5, 0.1, 1.5);
    attacker_container = await SceneLoader.LoadAssetContainerAsync(
      "models/",
      "AttackAnim.glb",
      scene
    );

    // attacker_container.meshes[0].isVisible = false;

    // let entries0 = attacker_container.addAllToScene();
    // entries0.rootNodes[0].position = new Vector3(1.5, 0.1, 1.5);
    // entries0.meshes[0].isEnabled = false;
    // container.rootNodes[0].isReady = false
    //        container.rootNodes[0].isEnabled = false

    //  let attacker1 = attacker_container.instantiateModelsToScene()
    // let entries = attacker_container.instantiateModelsToScene();
    // entries.rootNodes[0].isVisible = false;
    // //  let i = 0
    // entries.rootNodes[0].position = new Vector3(0, 0.1, 1.5);
    // entries.rootNodes[0].rotation = new Vector3(0, (180 * Math.PI) / 180, 0);
    // entries.animationGroups[0].play(true);
    //  for (var node of entries.rootNodes) {
    //      node.position.x += 1;
    //  }
    //   attacker1.isEnabled = false
    // attacker.meshes[0].isEnabled = false

    // scene.addMesh(attacker_container.meshes[0]);

    // attacker_container = await SceneLoader.ImportMeshAsync(
    //         "",
    //   "models/",
    //   "AttackAnim.glb",
    //   scene
    // )
    // templating_attacker();

    // async function templating_attacker() {
    // let i = 0;
    //   for (let attacker of attackers) {
    //     attacker = attacker_container.instantiateModelsToScene();
    //     attacker.meshes[0].position = new Vector3(0, 0.1, 1.5 + i);
    //     // attacker.position.x = 4.5 - i
    //     const attacker_cylinder = MeshBuilder.CreateCylinder("cylinder");
    //     attacker_cylinder.isVisible = false;
    //     attacker_cylinder.setParent(attacker.meshes[0], true, true);
    //     // attacker.isEnbaled = true;

    //     const attacker_klushka = MeshBuilder.CreateCylinder(
    //       "klushka",
    //       {},
    //       scene
    //     );
    //     attacker_klushka.scaling = new Vector3(0.1, 0.2, 0.45);

    //     attacker_klushka.setParent(attacker.meshes[0], true, true);

    //     attacker_klushka.position = new Vector3(0.2, 0.1, 1);
    //     attacker.meshes[0].position = new Vector3(4, 0.1, 1.5);
    //     attacker_klushka.isVisible = false;
    //     attackers.push(attacker);
    //     box.actionManager.registerAction(
    //       new ExecuteCodeAction(
    //         {
    //           trigger: ActionManager.OnIntersectionEnterTrigger,
    //           parameter: attacker_klushka,
    //         },
    //         () => {
    //           console.log("kicked by attacker");
    //           // console.log("damir privet");
    //           anim1.start(false, 2.0, anim1.from, anim1.to, false);
    //           console.log(anim1);
    //           console.log(
    //             "sh_collider",
    //             shaiba.meshes[0].getAbsolutePosition()
    //           );
    //           console.log(
    //             "attacker_klushka",
    //             attacker_klushka.getAbsolutePosition()
    //           );

    //           let angle = calculateReboundAngleXZ(
    //             sh_collider.getAbsolutePosition(),
    //             attacker_klushka.getAbsolutePosition()
    //           );
    //           global_speed += 4;
    //           console.log("angle", angle);
    //           reboundAngle = angle;
    //           // xDirection = 1;

    //           let time = 1500;
    //           if (!shaiba_timer) {
    //             shaiba_timer = setTimeout(() => {
    //               shaiba_invisible = true;
    //               plane_miss.isVisible = true;
    //             }, time - 300);
    //           }
    //           if (!timer && !timer_rounded) {
    //             timer = setTimeout(() => {
    //               player_kicked = false;
    //               plane_miss.isVisible = false;

    //               sh_collider.position = new Vector3(0, 0, sh_collider_z_start);
    //               shaiba_invisible = false;
    //               clearTimeout(shaiba_timer);
    //               shaiba_timer = null;
    //               clearTimeout(timer_rounded);
    //               timer = null;
    //               timer_rounded = null;
    //             }, time);
    //           }

    //           // if (!timer && !timer_rounded) {
    //           //   timer = setTimeout(() => {
    //           //     player_kicked = false;
    //           //     sh_collider.position = new Vector3(0, 0, 0);
    //           //     // player.meshes[0].rotation = new Vector3(
    //           //     //   0,
    //           //     //   angles_player.default * (Math.PI / 180),
    //           //     //   0
    //           //     // );
    //           //     timer = null;
    //           //     timer_rounded = null;
    //           //     clearTimeout(timer_rounded);
    //           //   }, 1500);
    //           // }

    //           // speed = 3;
    //         }
    //       )
    //     );
    //     i++;
    //   }
    // }

    // const cylinder_right = MeshBuilder.CreateCylinder(
    //   "cylinder_right",
    //   { height: 1, diameter: 3 },
    //   scene
    // );
    // cylinder_right.isVisible = false;
    // cylinder_right.position = new Vector3(5.59341, 0.5, -1.44268);
    // const cylinder_left = MeshBuilder.CreateCylinder("cylinder_left", {
    //   height: 1,
    //   diameter: 3,
    // });
    // cylinder_left.isVisible = false;
    // cylinder_left.position = new Vector3(5.59341, 0.5, 1.33417);

    //   function calculateBounceAngle(velocity, pointOfContact, centerOfCurvature) {
    //     // Отражение вектора скорости относительно нормали
    //     // v' = v - 2 * (v . n) * n
    //     // где v' - новый вектор скорости после отскока,
    //     // v - вектор скорости до отскока,
    //     // n - нормаль к поверхности в точке столкновения,
    //     // . обозначает скалярное произведение векторов
    //     // Вычисляем вектор от центра к точке соприкосновения
    //     let direction = pointOfContact.subtract(centerOfCurvature);
    //     // Нормализуем вектор до длины 1, чтобы получить нормаль
    //     let normal = direction.normalize();
    //     let dotProduct = velocity.dot(normal);
    //     let reflection = velocity.subtract(normal.scale(2 * dotProduct));
    //     return reflection;
    // }

    function calculateBounceAngle(velocity, pointOfContact, centerOfCurvature) {
      // Вычисляем вектор от центра к точке соприкосновения для нахождения направления нормали
      let direction = pointOfContact.subtract(centerOfCurvature);

      // Нормализуем вектор до единичной длины, чтобы получить нормаль поверхности в точке соприкосновения
      let normal = direction.normalize();

      // Вычисляем скалярное произведение вектора скорости и нормали
      let dotProduct = velocity.dot(normal);

      // Вычисляем вектор отражения по формуле: v' = v - 2 * (v . n) * n
      let reflection = velocity.subtract(normal.scale(2 * dotProduct));

      // Вычисляем угол между исходной скоростью и отраженной в радианах
      let angleRadians = Math.acos(
        velocity.normalize().dot(reflection.normalize())
      );

      // Конвертируем угол в градусы
      let angleDegrees = angleRadians * (180 / Math.PI);

      // Возвращаем угол в градусах
      return angleDegrees;
    }

    function calculateReboundVector(
      ballPosition,
      ballRadius,
      boxPosition,
      boxCornerRadius
    ) {
      // Рассчитываем вектор от центра шайбы до центра триггеровой зоны
      const centerToTrigger = boxPosition
        .subtract(ballPosition)
        .normalize()
        .scale(boxCornerRadius - ballRadius);

      // Проверяем, находится ли шайба внутри триггеровой зоны

      // Расчет вектора отскока
      const exactBallPosition = ballPosition.add(centerToTrigger);
      const exactBallToBox = boxPosition.subtract(exactBallPosition);
      const exactBallToBoxDistance = exactBallToBox.length();

      // Проверяем, находится ли точка контакта в области скругления или на основной поверхности коробки
      // const isInsideCorner = exactBallToBoxDistance < boxCornerRadius;
      const isInsideCorner = true;

      if (isInsideCorner) {
        const normal = exactBallToBox.normalize();
        const tangent = new Vector3(-normal.z, 0, normal.x); // Вычисляем тангентуальный вектор в плоскости скругления
        const reboundVector = normal
          .scale(ballRadius)
          .add(
            tangent.scale(
              ballRadius *
                Math.sqrt(1 - Math.pow(ballRadius / boxCornerRadius, 2))
            )
          );
        return reboundVector;
      } else {
        const normal = new Vector3(0, 0, 1); // Направление отскока вдоль оси Z
        return normal.scale(ballRadius);
      }
    }
    //

    function toStart() {}

    player = await SceneLoader.ImportMeshAsync(
      "",
      "./models/",
      "TestPlayerV1.glb",
      scene
    );
    player.meshes[0].position.y = 0.2;
    player.meshes[0].position.x = -0.5;

    box.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnIntersectionEnterTrigger,
          parameter: cube_vorota,
        },
        () => {
          console.log("goal");
          setGoals_();
          setDisabled(false);
          setTimeout(() => {
            setDisabled(true);
          }, 1200);
          // console.log("damir privet");
          sh_collider.position = new Vector3(0, 0, sh_collider_z_start);
          clearTimeout(shaiba_timer);
          setCountShaibas_(shaiba_count + 1);

          shaiba_invisible = false;
          plane_miss.isVisible = false;

          // player.meshes[0].rotation = new Vector3(
          //   0,
          //   angles_player.default * (Math.PI / 180),
          //   0
          // )

          clearTimeout(timer_rounded);
          clearTimeout(timer);
          timer_rounded = null;
          timer = null;
          shaiba_timer = null;

          // speed = 3;
          player_kicked = false;
        }
      )
    );

    box.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnIntersectionEnterTrigger,
          parameter: cube1,
        },
        () => {
          console.log("штанга справа");
          setShtanga_();
          // console.log("damir privet");

          // sh_collider.position = new Vector3(0, 0, sh_collider_z_start);
          // clearTimeout(shaiba_timer);

          // shaiba_invisible = false;
          // // player.meshes[0].rotation = new Vector3(
          // //   0,
          // //   angles_player.default * (Math.PI / 180),
          // //   0
          // // )

          // clearTimeout(timer_rounded);
          // clearTimeout(timer);
          // timer_rounded = null;
          // timer = null;
          // shaiba_timer = null;

          // speed = 3;
          // player_kicked = false;
          // // console.log("damir privet");
          let angle = calculateReflectionAngleShtanga();
          console.log("angle", angle);
          reboundAngle = angle;

          let time = 1500;
          shtanga_right.isVisible = true;

          clearTimeout(timer_shtanga_right);
          timer_shtanga_right = setTimeout(() => {
            shtanga_right.isVisible = false;
          }, time - 700);
        }
      )
    );
    const outline_box = MeshBuilder.CreateBox("outline_box", {});
    outline_box.isVisible = false;
    outline_box.position = new Vector3(0, 0, 0);
    outline_box.scaling = new Vector3(19, 0.5, 9);
    outline_box.actionManager = new ActionManager(scene);

    box.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnIntersectionExitTrigger,
          parameter: outline_box,
        },
        () => {
          // console.log("damir privet");
          sh_collider.position = new Vector3(0, 0, sh_collider_z_start);
          setCountShaibas_(shaiba_count - 1);

          clearTimeout(shaiba_timer);

          shaiba_invisible = false;
          plane_miss.isVisible = false;

          // player.meshes[0].rotation = new Vector3(
          //   0,
          //   angles_player.default * (Math.PI / 180),
          //   0
          // )

          clearTimeout(timer_rounded);
          clearTimeout(timer);
          timer_rounded = null;
          timer = null;
          shaiba_timer = null;

          // speed = 3;
          player_kicked = false;
          // // console.log("damir privet");
          // let angle = calculateReboundAngleXZ(
          //   sh_collider.getAbsolutePosition(),
          //   cube.getAbsolutePosition()
          // );
          // console.log("angle", angle);
          // reboundAngle = angle;

          // let time = 1500;
          // if (!shaiba_timer) {
          //   shaiba_timer = setTimeout(() => {
          //     shaiba_invisible = true;
          //     plane_miss.isVisible = true;
          //   }, time - 300);
          // }
          // if (!timer && !timer_rounded) {
          //   timer = setTimeout(() => {
          //     player_kicked = false;
          //     plane_miss.isVisible = false;

          //     sh_collider.position = new Vector3(0, 0, sh_collider_z_start);
          //     shaiba_invisible = false;

          //     clearTimeout(shaiba_timer);
          //     shaiba_timer = null;
          //     clearTimeout(timer_rounded);
          //     timer = null;
          //     timer_rounded = null;
          //   }, time);
          // }
        }
      )
    );

    box.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnIntersectionEnterTrigger,
          parameter: cube,
        },
        () => {
          console.log("штанга слева");
          setShtanga_();
          // console.log("damir privet");
          // sh_collider.position = new Vector3(0, 0, sh_collider_z_start);
          // clearTimeout(shaiba_timer);

          // shaiba_invisible = false;
          // // player.meshes[0].rotation = new Vector3(
          // //   0,
          // //   angles_player.default * (Math.PI / 180),
          // //   0
          // // )

          // clearTimeout(timer_rounded);
          // clearTimeout(timer);
          // timer_rounded = null;
          // timer = null;
          // shaiba_timer = null;

          // speed = 3;
          // player_kicked = false;
          // // console.log("damir privet");
          let angle = calculateReflectionAngleShtanga();
          console.log("angle", angle);
          reboundAngle = angle;

          let time = 1500;
          shtanga_left.isVisible = true;

          clearTimeout(timer_shtanga_left);
          timer_shtanga_left = setTimeout(() => {
            shtanga_left.isVisible = false;
          }, time - 700);
          // if (!shaiba_timer) {
          //   shaiba_timer = setTimeout(() => {
          //     shaiba_invisible = true;
          //     plane_miss.isVisible = true;
          //     setCountShaibas_(shaiba_count - 1);
          //   }, time - 300);
          // }
          // if (!timer && !timer_rounded) {
          //   timer = setTimeout(() => {
          //     player_kicked = false;
          //     plane_miss.isVisible = false;

          //     sh_collider.position = new Vector3(0, 0, sh_collider_z_start);
          //     shaiba_invisible = false;

          //     clearTimeout(shaiba_timer);
          //     shaiba_timer = null;
          //     clearTimeout(timer_rounded);
          //     timer = null;
          //     timer_rounded = null;
          //   }, time);
          // }
        }
      )
    );

    let back_field = MeshBuilder.CreateBox("back_field", {});
    back_field.position = new Vector3(7.15026, 0, 0);
    back_field.scaling = new Vector3(1, 1, 2.80799);
    back_field.isVisible = false;

    const left_bortik = MeshBuilder.CreateBox("left_bortik", {});
    left_bortik.position = new Vector3(0, 0, 3.31672);
    left_bortik.scaling = new Vector3(26.4, 1, 0.15);
    left_bortik.isVisible = false;

    const right_bortik = MeshBuilder.CreateBox("right_bortik", {});
    right_bortik.position = new Vector3(0, 0, -3.26011);
    right_bortik.scaling = new Vector3(26.4, 1, 0.15);
    right_bortik.isVisible = false;

    const vorota_right_bortik = MeshBuilder.CreateBox(
      "vorota_right_bortik",
      {}
    );
    vorota_right_bortik.position = new Vector3(6.87, 0, -1);
    vorota_right_bortik.scaling = new Vector3(1.2, 1, 0.2);
    vorota_right_bortik.rotation = new Vector3(0, -180 * (Math.PI / 180), 0);
    vorota_right_bortik.isVisible = false;

    const vorota_left_bortik = MeshBuilder.CreateBox("vorota_left_bortik", {});
    vorota_left_bortik.position = new Vector3(6.87, 0, 1);
    vorota_left_bortik.scaling = new Vector3(1.2, 1, 0.2);
    vorota_left_bortik.rotation = new Vector3(0, 180 * (Math.PI / 180), 0);
    vorota_left_bortik.isVisible = false;

    const vorota_middle_bortik = MeshBuilder.CreateBox(
      "vorota_middle_bortik",
      {}
    );
    vorota_middle_bortik.position = new Vector3(7.18, 0, 0);
    vorota_middle_bortik.rotation = new Vector3(0, 90 * (Math.PI / 180), 0);
    vorota_middle_bortik.scaling = new Vector3(1.54, 1, 0.2);
    // vorota_middle_bortik.rotation = new Vector3(0, -31 * (Math.PI / 180), 0);
    vorota_middle_bortik.isVisible = false;

    const background = MeshBuilder.CreateBox("background", {});
    background.position = new Vector3(-2, 0, 0);
    background.scaling = new Vector3(1, 1, 8);
    background.isVisible = false;
    // box.actionManager.registerAction(
    //   new ExecuteCodeAction(
    //     {
    //       trigger: ActionManager.OnIntersectionExitTrigger,
    //       parameter: back_field,
    //     },
    //     () => {
    //       clearTimeout(timer);
    //       clearTimeout(timer_rounded);

    //       player_kicked = false;
    //       sh_collider.position = new Vector3(0, 0, 0);
    //       // player.meshes[0].rotation = new Vector3(
    //       //   0,
    //       //   angles_player.default * (Math.PI / 180),
    //       //   0
    //       // );
    //       timer = null;
    //       timer_rounded = null;

    //       // xDirection = 1;
    //     }
    //   )
    // );
    left_bortik.actionManager = new ActionManager(scene);
    // left_bortik.actionManager.registerAction(
    //   new ExecuteCodeAction(
    //     {
    //       trigger: ActionManager.OnIntersectionEnterTrigger,
    //       parameter: sh_collider,
    //     },
    //     () => {
    //       zDirection = 1;
    //       reboundAngle = calculateReflectionAngle();
    //       console.log("reboundAngle", reboundAngle); // if(prevPosition.x > sh_collider.getAbsolutePosition().x){

    //       if (!timer && !timer_rounded) {
    //         timer = setTimeout(() => {
    //           player_kicked = false;
    //           sh_collider.position = new Vector3(0, 0, 0);
    //           // player.meshes[0].rotation = new Vector3(
    //           //   0,
    //           //   angles_player.default * (Math.PI / 180),
    //           //   0
    //           // );
    //           clearTimeout(timer_rounded);

    //           timer = null;
    //           timer_rounded = null;
    //         }, 3500);
    //       }
    //     }
    //   )
    // );
    right_bortik.actionManager = new ActionManager(scene);
    // right_bortik.actionManager.registerAction(
    //   new ExecuteCodeAction(
    //     {
    //       trigger: ActionManager.OnIntersectionEnterTrigger,
    //       parameter: sh_collider,
    //     },
    //     () => {
    //       zDirection = 1;
    //       reboundAngle = calculateReflectionAngle();
    //       console.log("reboundAngle", reboundAngle);
    //       if (!timer && !timer_rounded) {
    //         timer = setTimeout(() => {
    //           player_kicked = false;
    //           sh_collider.position = new Vector3(0, 0, 0);
    //           // player.meshes[0].rotation = new Vector3(
    //           //   0,
    //           //   angles_player.default * (Math.PI / 180),
    //           //   0
    //           // );
    //           clearTimeout(timer_rounded);

    //           timer = null;
    //           timer_rounded = null;
    //         }, 3500);
    //       }
    //     }
    //   )
    // );

    box.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnIntersectionEnterTrigger,
          parameter: background,
        },
        () => {
          shaiba_invisible = false;
          setCountShaibas_(shaiba_count - 1);
          clearTimeout(shaiba_timer);
          shaiba_timer = null;
          clearTimeout(timer);
          clearTimeout(timer_rounded);
          plane_miss.isVisible = false;

          player_kicked = false;
          sh_collider.position = new Vector3(0, 0, sh_collider_z_start);
          // player.meshes[0].rotation = new Vector3(
          //   0,
          //   angles_player.default * (Math.PI / 180),
          //   0
          // );
          timer = null;
          timer_rounded = null;

          // xDirection = 1;
        }
      )
    );

    player.meshes[0].rotation = new Vector3(0, 180 * (Math.PI / 180), 0);

    hud_glb = await SceneLoader.ImportMeshAsync(
      "",
      "./models/",
      "HUD.glb",
      scene
    );
    // hud_glb.meshes[0].bakeCurrentTransformIntoVertices();

    // hud_glb.meshes[0].setParent(player.meshes[0], true, true);
    hud_glb.meshes[0].scaling = new Vector3(1.2, 1.2, 1.2);
    hud_glb.meshes[0].position = new Vector3(0.1, 0.075, -1);
    // hud_glb.meshes[0].rotation = new Vector3(0, 180 * (Math.PI / 180), 0);

    // player.meshes[0].position = new Vector3(0, 0.2, 0);
    // sh_collider = scene.getNodeByName("Cylinder");
    // console.log("sh_collider", sh_collider);
    klushka = MeshBuilder.CreateCylinder("klushka", {}, scene);
    klushka.scaling = new Vector3(0.3, 0.3, 0.3);

    let klushka_center_box = MeshBuilder.CreateBox(
      "klushka_center_box",
      {},
      scene
    );
    klushka_center_box.isVisible = false;
    klushka_center_box.scaling = new Vector3(0.4, 0.4, 0.2);
    klushka_center_box.position = new Vector3(0.3, 0.1, 0.4);
    klushka_center_box.setParent(scene.getNodeByName("Key.003"), true, true);

    klushka.setParent(scene.getNodeByName("Key.003"), true, true);
    klushka.isVisible = false;
    klushka.renderOutline = true;
    klushka.position = new Vector3(0, 0.2, 0);

    // const korobka_collider = scene.getNodeByName("BockBortick");
    // korobka_collider.isVisible = false;
    // korobka_collider.actionManager = new ActionManager(scene);

    // korobka_collider.actionManager.registerAction(
    //   new ExecuteCodeAction(
    //     {
    //       trigger: ActionManager.OnIntersectionExitTrigger,
    //       parameter: sh_collider,
    //     },
    //     () => {
    //       console.log("DAMIIIIIIIIIIIIIIIIIR!!!!!! NOT WORKS!!!!");
    //       // zDirection = 1;
    //       reboundAngle = calculateReflectionAngle();
    //       console.log("reboundAngle", reboundAngle);
    //     }
    //   )
    // );
    const korobka_right_bortik = scene.getNodeByName("RightBortik");
    const korobka_left_bortik = scene.getNodeByName("LeftBortik");
    korobka_right_bortik.isVisible = false;
    korobka_left_bortik.isVisible = false;
    korobka_right_bortik.actionManager = new ActionManager(scene);
    korobka_right_bortik.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnIntersectionEnterTrigger,
          parameter: sh_collider,
        },
        () => {
          console.log("DAMIIIIIIIIIIIIIIIIIR!!!!!! NOT WORKS!!!!");
          // zDirection = 1;
          reboundAngle = calculateReflectionAngle();
          console.log("reboundAngle", reboundAngle);
        }
      )
    );

    korobka_left_bortik.actionManager = new ActionManager(scene);
    korobka_left_bortik.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnIntersectionEnterTrigger,
          parameter: sh_collider,
        },
        () => {
          console.log("DAMIIIIIIIIIIIIIIIIIR!!!!!! NOT WORKS!!!!");
          // zDirection = 1;
          reboundAngle = calculateReflectionAngle();
          console.log("reboundAngle", reboundAngle);
        }
      )
    );

    vorota_left_bortik.actionManager = new ActionManager(scene);
    vorota_left_bortik.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnIntersectionEnterTrigger,
          parameter: sh_collider,
        },
        () => {
          console.log("VOROTA LEFT BORTIK");
          // zDirection = 1;
          reboundAngle = calculateReflectionAngle();
          console.log("reboundAngle", reboundAngle);
          // zDirection = -1;
        }
      )
    );
    vorota_right_bortik.actionManager = new ActionManager(scene);
    vorota_right_bortik.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnIntersectionEnterTrigger,
          parameter: sh_collider,
        },
        () => {
          console.log("VOROTA RIGHT BORTIK");
          // zDirection = 1;
          reboundAngle = calculateReflectionAngle();
          console.log("reboundAngle", reboundAngle);
        }
      )
    );

    vorota_middle_bortik.actionManager = new ActionManager(scene);
    vorota_middle_bortik.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnIntersectionEnterTrigger,
          parameter: sh_collider,
        },
        () => {
          console.log("VOROTA MIDDLE BORTIK");
          // zDirection = 1;
          reboundAngle = calculateReflectionAngle();
          console.log("reboundAngle", reboundAngle);
          console.log("reboundAngle VOROTA MIDDLE BORTIK", reboundAngle);
        }
      )
    );
    // scene.getNodeByName("BacksCollider").isVisible = false;
    scene.getNodeByName("MiddleBortic").isVisible = false;

    const top_middler_bortik = MeshBuilder.CreateBox(
      "top_middler_bortik",
      {},
      scene
    );
    top_middler_bortik.isVisible = false;
    top_middler_bortik.scaling = new Vector3(0.152015, 0.3, 4.5);
    top_middler_bortik.position = new Vector3(7.38297, 0, 0.055405);

    top_middler_bortik.actionManager = new ActionManager(scene);

    top_middler_bortik.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnIntersectionEnterTrigger,
          parameter: sh_collider,
        },
        () => {
          console.log("TOP MIDDLE BORTIK");
          // zDirection = 1;
          reboundAngle = calculateReflectionAngle();
          console.log("reboundAngle TOP MIDDLE BORTIK", reboundAngle);
        }
      )
    );

    // const top_bortik_collider = scene.getNodeByName("MiddleBortic");
    // top_bortik_collider.isVisible = true;
    // top_bortik_collider.actionManager = new ActionManager(scene);

    // top_bortik_collider.actionManager.registerAction(
    //   new ExecuteCodeAction(
    //     {
    //       trigger: ActionManager.OnIntersectionExitTrigger,
    //       parameter: sh_collider,
    //     },
    //     () => {
    //       console.log("TOP BORTIK");
    //       // xDirection = -1;
    //       const temp_rebound_angle = calculateReflectionAngle();
    //       if(temp_rebound_angle  === 0 || temp_rebound_angle === -0){
    //         reboundAngle = 90;
    //       } else{
    //         reboundAngle = temp_rebound_angle
    //       }
    //       console.log("reboundAngle TOP BORTIK", reboundAngle);

    //     }
    //   )
    // );
    //LeftRoundBortick
    // for(let i = 1; i < 4; i++){
    //   console.log(i)
    //   const right_particle_bortik = scene.getNodeByName("RightCollider_" + i);
    //   right_particle_bortik.isVisible = true;
    //   right_particle_bortik.actionManager = new ActionManager(scene);

    //   right_particle_bortik.actionManager.registerAction(
    //     new ExecuteCodeAction(
    //       {
    //         trigger: ActionManager.OnIntersectionEnterTrigger,
    //         parameter: sh_collider,
    //       },
    //       () => {
    //         console.log("RIGHT PARTICLE BORTIK", i);
    //         reboundAngle = calculateReflectionAngle()
    //         console.log("reboundAngle RIGHT PARTICLE BORTIK", i, reboundAngle);
    //       }
    //     )
    //   )
    // }
    const right_rounded_bortik_collider =
      scene.getNodeByName("RightRoundBortick");
    right_rounded_bortik_collider.isVisible = false;

    right_rounded_bortik_collider.actionManager = new ActionManager(scene);

    right_rounded_bortik_collider.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnIntersectionExitTrigger,
          parameter: sh_collider,
        },
        () => {
          console.log("RIGHT ROUND BORTIK");
          let center_of_curve = new Vector3(5.59341, 0.5, -1.44268);
          // xDirection = -1
          // reboundAngle = calculateBounceAngle(shaiba_velocity, sh_collider.getAbsolutePosition() ,center_of_curve);
          reboundAngle = 90;
        }
      )
    );
    //RightRoundBortick
    const left_rounded_bortik_collider =
      scene.getNodeByName("LeftRoundBortick");
    left_rounded_bortik_collider.isVisible = false;

    left_rounded_bortik_collider.actionManager = new ActionManager(scene);

    left_rounded_bortik_collider.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnIntersectionExitTrigger,
          parameter: sh_collider,
        },
        () => {
          console.log("LEFT ROUND BORTIK");
          let center_of_curve = new Vector3(5.59341, 0.5, -1.44268);
          // xDirection = -1
          // reboundAngle = calculateBounceAngle(shaiba_velocity, sh_collider.getAbsolutePosition() ,center_of_curve);
          reboundAngle = -90;
        }
      )
    );
    // korobka_collider.actionManager.registerAction(
    //   new ExecuteCodeAction(
    //     {
    //       trigger: ActionManager.OnIntersectionEnterTrigger,
    //       parameter: sh_collider,
    //     },
    //     () => {
    //       console.log('DAMIIIIIIIIIIIIIIIIIR!!!!!! WORKS!!!!')
    //       zDirection = 1;
    //       reboundAngle = calculateReflectionAngle();
    //       console.log("reboundAngle", reboundAngle);
    //     }
    //   )
    // );  // let start;
    // klushka.isVisible = true

    var initialY = 0;
    // let force = 0

    scene.onPointerDown = (e) => {
      console.log(e);
      flag_downed = true;
      if (!player_kicked) {
        klushka_up = false;
        klushka_down = true;
        initialY = e.clientY;
        // start = Date.now();
        // klushka_down = true;
        console.log("one");
        updatePlayerRotation(180);
        return_klushka = false;
      }
    };

    var advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");

    var panel = new StackPanel();
    panel.width = "220px";
    panel.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    panel.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    advancedTexture.addControl(panel);

    var header = new TextBlock();
    header.text = "Y-rotation: 0 deg";
    header.height = "30px";
    header.color = "white";
    // panel.addControl(header);

    var slider = new Slider();
    slider.minimum = 0;
    slider.maximum = 20;
    slider.value = 0;
    slider.isVertical = true;
    slider.displayThumb = false;
    slider.height = "600px";
    slider.width = "20px";
    slider.color = "#00ff00";
    slider.background = "#fff";
    slider.onValueChangedObservable.add(function (value) {});
    // panel.addControl(slider);
    function movePlayer(newXPosition) {
      target_player_z = -newXPosition;
    }
    function map_range(value, low1, high1, low2, high2) {
      return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);
    }

    // let temp_target_player_z = -;
    scene.onPointerMove = (e) => {
      // Получаем позицию курсора в координатах холста
      let canvasRect = scene.getEngine().getRenderingCanvasClientRect();
      let canvasX = e.clientX - canvasRect.left;
      // console.log("canvasX", canvasX, "canvasRect.width", canvasRect.width);
      var deltaY = e.clientY - initialY;
      var progress = Math.min(Math.max(deltaY / 10, 0), 20); // Преобразуем изменение высоты в прогресс от 0 до maxForce
      // force =
      // console.log(progress);
      if (flag_downed) {
        slider.value = progress;
      }
      // Преобразуем позицию курсора в координаты сцены
      let sceneWidth = scene.getEngine().getRenderWidth(); // Задайте ширину сцены в единицах Babylon.js

      setPower(Math.min(Math.max(deltaY / 10, 0), 22));
      // let newXPosition =
      //   (canvasX / canvasRect.width) * sceneWidth - sceneWidth / 2;

      // console.log("newXPosition", newXPosition)
      // console.log(newYPosition)

      // Устанавливаем границы для newXPosition, чтобы объект не выходил за края сцены
      // newXPosition = Math.max(newXPosition, -3.2 / 2);
      // newXPosition = Math.min(newXPosition, 3.2 /2);
      // let halfCanvasWidth = canvasRect.width / 2;
      // // let newXPosition = (canvasX - halfCanvasWidth)
      // let newXPosition = 1
      // if(canvasX > halfCanvasWidth){
      //   newXPosition = 1
      // }
      // if(canvasX < halfCanvasWidth){
      //   newXPosition = -1
      // }
      // if(canvasX === halfCanvasWidth){
      //   newXPosition = 0
      // }
      // let one_percent = canvasRect.width / 100

      let newXPosition =
        (canvasX / canvasRect.width) * sceneWidth - sceneWidth / 2;

      // console.log(newYPosition)

      // Устанавливаем границы для newXPosition, чтобы объект не выходил за края сцены
      newXPosition = Math.max(newXPosition, -sceneWidth / 2);
      newXPosition = Math.min(newXPosition, sceneWidth / 2);

      // Обновляем позицию игрока
      let new_z = -newXPosition / 250;

      // console.log(new_z)
      if (new_z > -3 || new_z < 3) {
        player.meshes[0].position.z = new_z;
        hud_glb.meshes[0].position.z = new_z - 1;
      }
      // movePlayer(newXPosition);

      // Update the player's position
      // let new_z = -newXPosition / 300;

      // if (new_z > -3.2 && new_z < 3.2) {
      //   target_player_z = new_z;
      // }
      // console.log(e)
    };
    function updatePlayerRotation() {
      // Устанавливаем новый поворот игрока в зависимости от текущего прогресса
      target_angle_player = angles_player.pre_attack;
    }

    scene.onPointerUp = (e) => {
      flag_downed = false;
      setPower(0);

      if (!player_kicked && klushka_down) {
        slider.value = 0;
        klushka_up = true;
        klushka_down = false;

        setTimeout(() => {
          if (!player_kicked) {
            klushka_up = false;
          }
          return_klushka = true;
        }, 400);
        var deltaY = e.clientY - initialY;
        var force = Math.min(Math.max(deltaY / 10, 0), 8);
        console.log(force);
        if (force < 1) {
          force = 2;
        }

        global_speed = force;
        console.log("two");
        console.log(acceleration_global);
      }
    };

    klushka.actionManager = new ActionManager(scene);
    klushka_center_box.actionManager = new ActionManager(scene);
    klushka_center_box.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnIntersectionEnterTrigger,
          parameter: sh_collider,
        },
        () => {
          if (klushka_up && !player_kicked) {
            console.log("CYBIKKKKKKKKKKKK");
            reboundAngle = 360 * (Math.PI / 180);
            // xDirection = -1
            // console.log(sh_collider.position);
            klushka_up = false;
            player_kicked = true;
            // shaiba_invisible = true
            // setPlayerKicked(true);

            // klushka.position.x = 0.3;
            // console.log("sh_collider", shaiba.meshes[0].getAbsolutePosition());
            // console.log("klushka", klushka.getAbsolutePosition());

            let time = 3500;
            if (!shaiba_timer) {
              shaiba_timer = setTimeout(() => {
                shaiba_invisible = true;
                plane_miss.isVisible = true;
                setCountShaibas_(shaiba_count - 1);
              }, time - 300);
            }
            if (!timer && !timer_rounded) {
              timer = setTimeout(() => {
                player_kicked = false;
                plane_miss.isVisible = false;

                sh_collider.position = new Vector3(0, 0, sh_collider_z_start);
                shaiba_invisible = false;
                clearTimeout(shaiba_timer);
                shaiba_timer = null;
                clearTimeout(timer_rounded);
                timer = null;
                timer_rounded = null;
              }, time);
            }
            speed = 3;
          }
        }
      )
    );
    klushka.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnIntersectionEnterTrigger,
          parameter: sh_collider,
        },
        () => {
          if (klushka_up && !player_kicked) {
            console.log("klushka gives shaiba");
            // console.log(sh_collider.position);
            klushka_up = false;
            player_kicked = true;
            // shaiba_invisible = true
            // setPlayerKicked(true);
            // klushka.position.x = 0.3;
            console.log("sh_collider", shaiba.meshes[0].getAbsolutePosition());
            console.log("klushka", klushka.getAbsolutePosition());

            let angle = calculateReboundAngleXZ(
              sh_collider.getAbsolutePosition(),
              klushka.getAbsolutePosition()
            );
            console.log("angle", angle);
            reboundAngle = angle;
            let time = 3500;
            if (!shaiba_timer) {
              shaiba_timer = setTimeout(() => {
                shaiba_invisible = true;
                plane_miss.isVisible = true;
                setCountShaibas_(shaiba_count - 1);
              }, time - 300);
            }
            if (!timer && !timer_rounded) {
              timer = setTimeout(() => {
                player_kicked = false;
                plane_miss.isVisible = false;

                sh_collider.position = new Vector3(0, 0, sh_collider_z_start);
                shaiba_invisible = false;
                clearTimeout(shaiba_timer);
                shaiba_timer = null;
                clearTimeout(timer_rounded);
                timer = null;
                timer_rounded = null;
              }, time);
            }
            speed = 3;
          }
        }
      )
    );

    console.log(player.meshes);

    function calculateReflectionAngle(prevPosition, currentPosition) {
      // Вычисляем вектор скорости
      // let velocity = currentPosition.subtract(prevPosition);
      let velocity = shaiba_velocity;
      console.log("velocity", velocity);

      // Инвертируем компонент скорости по оси Z для отражения
      velocity.z = -velocity.z;

      // Нормализуем отраженный вектор скорости
      velocity.normalize();

      // Вычисляем угол отражения относительно оси X в плоскости XZ (в радианах)
      let angle = Math.atan2(velocity.z, velocity.x);
      // Возвращаем угол отражения в радианах, преобразованный из диапазона [-π, π] в [0, 2π]

      return angle < 0 ? angle + 2 * Math.PI : angle;
    }
    function calculateReflectionAngleShtanga(){
      // Вычисляем вектор скорости
      // let velocity = currentPosition.subtract(prevPosition);
      let velocity = shaiba_velocity;
      console.log("velocity", velocity);

      let z_velocity_abs = Math.abs(velocity.z);
      let x_velocity_abs = Math.abs(velocity.x);

      // Инвертируем компонент скорости по оси Z для отражения
      velocity.z = -velocity.z;
      if(x_velocity_abs > z_velocity_abs){
        velocity.x = -velocity.x;
      }

      // Нормализуем отраженный вектор скорости
      velocity.normalize();

      // Вычисляем угол отражения относительно оси X в плоскости XZ (в радианах)
      let angle = Math.atan2(velocity.z, velocity.x);
      // Возвращаем угол отражения в радианах, преобразованный из диапазона [-π, π] в [0, 2π]

      return angle < 0 ? angle + 2 * Math.PI : angle;
    }
    vratar = await SceneLoader.ImportMeshAsync(
      "",
      "./models/",
      "VratarPlayerV1.glb",
      scene
    );
    console.log(vratar.meshes);
    vratar.meshes[0].scaling = new Vector3(0.4, 0.4, -0.4);
    vratar.meshes[0].position = new Vector3(5.7, 0.1, 0);
    vratar.meshes[0].rotation = new Vector3(0, 0, 0);

    const vratar_box_cancel = MeshBuilder.CreateCylinder(
      "vratar_box_cancel",
      {}
    );
    vratar_box_cancel.setParent(vratar.meshes[0]);
    vratar_box_cancel.scaling = new Vector3(1, 1, 2.3);
    vratar_box_cancel.position = new Vector3(0, 1, 0);
    vratar_box_cancel.isVisible = false;

    box.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnIntersectionEnterTrigger,
          parameter: vratar_box_cancel,
        },
        () => {
          console.log("kicked by vratar");
          // console.log("damir privet");
          // anim1.start(false, 0.5, anim1.from, anim1.to, false);
          // console.log(anim1);
          console.log("sh_collider", shaiba.meshes[0].getAbsolutePosition());

          let angle = calculateReboundAngleXZ(
            shaiba.meshes[0].getAbsolutePosition(),
            vratar_box_cancel.getAbsolutePosition()
          );
          global_speed += 4;
          console.log("angle", angle);
          reboundAngle = angle;
          // xDirection = 1;
          // if (vratar_box_cancel.position.z > shaiba.meshes[0].position.z) {
          //   zDirection = -1;
          // }
          // if (vratar_box_cancel.position.z < shaiba.meshes[0].position.z) {
          //   zDirection = 1;
          // }

          // speed = 3;
        }
      )
    );

    const vratar_anim_trigger = MeshBuilder.CreateCylinder(
      "vratar_anim_trigger",
      {},
      scene
    );
    vratar_anim_trigger.setParent(vratar.meshes[0]);
    vratar_anim_trigger.scaling = new Vector3(4, 1, 3);
    vratar_anim_trigger.position = new Vector3(0, 1, 0);
    vratar_anim_trigger.isVisible = false;
    box.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnIntersectionEnterTrigger,
          parameter: vratar_anim_trigger,
        },
        () => {
          const anim = scene.getAnimationGroupByName("GoalKeeperDown");
          anim.start(false, 2, anim.from, anim.to, false);
          setTimeout(() => {
            const anim2 = scene.getAnimationGroupByName("GoalKeeperUP");
            anim2.start(false, 2, anim2.from, anim2.to, false);
          }, 300);
        }
      )
    );

    // for(let m of scene.meshes){
    //   m.isVisible = true;
    // }
    const canvas = scene.getEngine().getRenderingCanvas();

    // camera.attachControl(canvas, true);

    scene.activeCameras = [camera];

    // var skybox = MeshBuilder.CreateBox("skyBox", { size: 1000.0 }, scene);
    // var skyboxMaterial = new StandardMaterial("skyBox", scene);
    // skyboxMaterial.backFaceCulling = false;
    // skyboxMaterial.reflectionTexture = new CubeTexture(
    //   "http://176.99.167.65:3000/textures/environmentSpecular.env",
    //   scene
    // );
    // skyboxMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
    // skyboxMaterial.diffuseColor = new Color3(0, 0, 0);
    // skyboxMaterial.specularColor = new Color3(0, 0, 0);
    // skybox.material = skyboxMaterial;
    // skybox.position = new Vector3(0, 4, 0);
    // skybox.infiniteDistance = true;
    // skybox.intensity = 0;

    // Create default pipeline
    var defaultPipeline = new DefaultRenderingPipeline("default", true, scene, [
      camera,
    ]);

    defaultPipeline.depthOfField.focalLength = 0;

    // defaultPipeline.sharpenEnabled = true;
    defaultPipeline.sharpen.edgeAmount = 5;
    defaultPipeline.imageProcessingEnabled = true;
    defaultPipeline.imageProcessing.enablePixelPerfectMode = true;
    defaultPipeline.depthOfField.focalLength = 17.44; // focal length of the camera in millimeters

    defaultPipeline.imageProcessing.contrast = 1.3;
    defaultPipeline.imageProcessing.exposure = 0.3;
    defaultPipeline.bloomEnabled = true;
    defaultPipeline.bloomKernel = 60;
    defaultPipeline.bloomWeight = 0.2;
    defaultPipeline.bloomThreshold = 2;
    defaultPipeline.bloomScale = 1;
    defaultPipeline.imageProcessing.toneMappingEnabled = true;

    // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    const light = new HemisphericLight("light", new Vector3(5, 15, 0), scene);

    // Default intensity is 1. Let's dim the light a small amount
    light.intensity = 2.6;
    light.direction = new Vector3(0, 3, 0);

    // scene.getEngine().hideLoadingUI()
    // global_loading_screen = false;
    setLoading_back_visible(false);
  };
function calculateReboundAngleXZ(shaybaPosition, klushkaPosition) {
  // Вычислить вектор от центра клюшки к центру шайбы
  let collisionVector = {
    x: shaybaPosition.x - klushkaPosition.x,
    z: shaybaPosition.z - klushkaPosition.z,
  };

  // Нормализовать вектор столкновения
  let magnitude = Math.sqrt(collisionVector.x ** 2 + collisionVector.z ** 2);
  let normalVector = {
    x: collisionVector.x / magnitude,
    z: collisionVector.z / magnitude,
  };

  // Угол отскока шайбы будет равен арктангенсу отношения компоненты Z к компоненте X вектора нормали
  let reboundAngle = Math.atan2(normalVector.z, normalVector.x);
  // zDirection = Math.sign(shaybaPosition.z - klushkaPosition.z);
  console.log("reboundAngle", reboundAngle);
  // Угол в радианах относительно оси X
  return reboundAngle;
}
/**
 * Will run on every frame render.  We are spinning the box on y-axis.
 */
// speed = 5
const acceleration = -0.5; // Ускорение (единицы в секунду за секунду)
speed = 3;
let leftDirection_idle = true;
// let leftDirection_vratar_idle = true
let vratar_target_z = 0;
let range_vratar_movins_z = [-1.2, 1.2];
let attacker_target_z = 0;
let attacker_target_x = 0;
let range_attacker_movins_z = [3.1, -3.5];
let range_attacker_movins_x = [1, 5];

let ranges_attackers = [
  {
    z: [3.1, -3.5],
    x: [1.7, 2.7],
  },
  {
    z: [3.1, -3.5],
    x: [2.7, 3.7],
  },
  {
    z: [3.1, -3.5],
    x: [3.7, 5],
  },
];
function rand(min, max) {
  let r;

  while (!(r = Math.random())); // generate and check and use only r !== 0 values
  return r * (max - min) + min;
}
setInterval(() => {
  attacker_target_z = rand(
    range_attacker_movins_z[0],
    range_attacker_movins_z[1]
  );
  attacker_target_x = rand(
    range_attacker_movins_x[0],
    range_attacker_movins_x[1]
  );
  // console.log("attacker_target_z", attacker_target_z);
}, 1500);
let frames = 0;
let prev_pos_shaiba;

let leftDirection_vratar_idle = true;

let attackers_targets = [
  {
    x: 0,
    z: 0,
  },
  {
    x: 0,
    z: 0,
  },
  {
    x: 0,
    z: 0,
  },
];

const onRender = (scene) => {
  // console.log("paused", paused);
  if (!paused) {
    // console.log("onRender disabled", global_disabled);
    const deltaTimeInMillis = scene.getEngine().getDeltaTime();
    const deltaTimeInSeconds = deltaTimeInMillis / 1000;
    // if (player !== undefined) {
    //   let delta_k = 0.0005;
    //   let diff_z = target_player_z - player.meshes[0].getAbsolutePosition().z;
    //   let k_z = 1;
    //   if (diff_z < 0) {
    //     k_z = -1;
    //   }
    //   if (diff_z * k_z > delta_k) {
    //     player.meshes[0].position.z += delta_k * deltaTimeInMillis * k_z;
    //   }
    // }
    if (shaiba !== undefined) {
      if (shaiba_invisible) {
        // sh_collider2.material.alpha = 0.001
        // sh_collider.material.alpha = 0.5
        if (sh_collider.material.alpha > 0) {
          sh_collider.material.alpha -= 5 * deltaTimeInSeconds;
        }
        if (sh_collider2.material.alpha > 0) {
          sh_collider2.material.alpha -= 5 * deltaTimeInSeconds;
        }
      } else {
        if (sh_collider.material.alpha < 1) {
          sh_collider.material.alpha += 15 * deltaTimeInSeconds;
        }
        if (sh_collider2.material.alpha < 1) {
          sh_collider2.material.alpha += 15 * deltaTimeInSeconds;
        }
      }
      if (frames === 0) {
        prev_pos_shaiba = { ...sh_collider.getAbsolutePosition() };
      }
      frames++;

      // Обновляем скорость, добавляя ускорение
      if (speed > 0) {
        speed += -0.5 * deltaTimeInSeconds;
      }
      if (global_speed > 0) {
        global_speed += -0.8 * deltaTimeInSeconds;
      }
      // console.log('vratar', vratar.meshes[0])
      if (vratar !== undefined) {
        // console.log('vratar', vratar.meshes[0])

        if (leftDirection_vratar_idle) {
          vratar.meshes[0].position.z +=
            table_levels[level].vratar.speed * deltaTimeInMillis;
          if (vratar.meshes[0].position.z > 1.2) {
            leftDirection_vratar_idle = false;
          }
        } else {
          vratar.meshes[0].position.z -=
            table_levels[level].vratar.speed * deltaTimeInMillis;
          if (vratar.meshes[0].position.z < -1.2) {
            leftDirection_vratar_idle = true;
          }
        }
      }
      if (table_levels[level].attackers.count > 0) {
        for (let i = 0; i < table_levels[level].attackers.count; i++) {
          if (i === 0) {
            // attacker_container.meshes[0].position.z;

            let diff_z =
              attackers_targets[i].z -
              attacker_container.meshes[0].getAbsolutePosition().z;
            let k_z = 1;
            if (diff_z < 0) {
              k_z = -1;
            }
            if (diff_z * k_z > table_levels[level].attackers.speed) {
              attacker_container.meshes[0].position.z +=
                table_levels[level].attackers.speed * deltaTimeInSeconds * k_z;
            }
            let diff_x =
              attackers_targets[i].x -
              attacker_container.meshes[0].getAbsolutePosition().x;
            let k_x = 1;
            if (diff_x < 0) {
              k_x = -1;
            }
            if (diff_x * k_x > table_levels[level].attackers.speed) {
              attacker_container.meshes[0].position.x +=
                table_levels[level].attackers.speed * deltaTimeInSeconds * k_x;
            }
          } else {
            let attacker_i = attackers[i - 1];
            //              new_attacker.rootNodes[0].position = new Vector3(x_first_pos,0.1,z_first_pos)
            let diff_z =
              attackers_targets[i].z -
              attacker_i.rootNodes[0].getAbsolutePosition().z;
            let k_z = 1;
            if (diff_z < 0) {
              k_z = -1;
            }
            if (diff_z * k_z > table_levels[level].attackers.speed) {
              attacker_i.rootNodes[0].position.z +=
                table_levels[level].attackers.speed * deltaTimeInSeconds * k_z;
            }
            let diff_x =
              attackers_targets[i].x -
              attacker_i.rootNodes[0].getAbsolutePosition().x;
            let k_x = 1;
            if (diff_x < 0) {
              k_x = -1;
            }
            if (diff_x * k_x > table_levels[level].attackers.speed) {
              attacker_i.rootNodes[0].position.x +=
                table_levels[level].attackers.speed * deltaTimeInSeconds * k_x;
            }
          }
        }
      }
      // if (attacker !== undefined) {
      //   let diff_z =
      //     attacker_target_z - attacker.meshes[0].getAbsolutePosition().z;
      //   let k_z = 1;
      //   if (diff_z < 0) {
      //     k_z = -1;
      //   }
      //   if (diff_z * k_z > 0.8) {
      //     attacker.meshes[0].position.z += 0.8 * deltaTimeInSeconds * k_z;
      //   }
      //   let diff_x =
      //     attacker_target_x - attacker.meshes[0].getAbsolutePosition().x;
      //   let k_x = 1;
      //   if (diff_x < 0) {
      //     k_x = -1;
      //   }
      //   if (diff_x * k_x > 0.8) {
      //     attacker.meshes[0].position.x += 0.8 * deltaTimeInSeconds * k_x;
      //   }
      // }

      if (klushka) {
        if (!player_kicked) {
          if (leftDirection_idle) {
            sh_collider.position.z += 0.002 * deltaTimeInMillis;
            if (sh_collider.position.z > 2.8) {
              leftDirection_idle = false;
            }
          } else {
            sh_collider.position.z -= 0.002 * deltaTimeInMillis;
            if (sh_collider.position.z < -1.4) {
              leftDirection_idle = true;
            }
          }
        }

        if (return_klushka) {
          let player_degrees = player.meshes[0].rotation.y * (180 / Math.PI);
          if (angles_player.default + 0.1 > player_degrees) {
            player.meshes[0].rotation.y +=
              0.1 * (Math.PI / 180) * deltaTimeInMillis;
          }
        }
        if (klushka_up && !klushka_down && !return_klushka) {
          let player_degrees = player.meshes[0].rotation.y * (180 / Math.PI);
          if (player_degrees > 170) {
            player.meshes[0].rotation.y -=
              0.25 * (Math.PI / 180) * deltaTimeInMillis;
          }
        }
        if (klushka_down && !klushka_up) {
          let player_degrees = player.meshes[0].rotation.y * (180 / Math.PI);
          if (target_angle_player !== player_degrees) {
            if (target_angle_player > player_degrees) {
              player.meshes[0].rotation.y +=
                0.1 * (Math.PI / 180) * deltaTimeInMillis;
              // if(hud_glb  !== undefined){
              //   hud_glb.rotation.y += 0.25 * (Math.PI / 180) * deltaTimeInMillis;
              // }
            }
          }
        }
      }

      // Обновляем позицию, используя новую скорость
      if (player_kicked) {
        // console.log(reboundAngle);
        sh_collider.position.x +=
          global_speed *
          Math.cos(reboundAngle) *
          deltaTimeInSeconds *
          -1 *
          xDirection;
        sh_collider.position.z +=
          global_speed *
          Math.sin(reboundAngle) *
          deltaTimeInSeconds *
          zDirection;
      }
      if (frames >= 2) {
        let current_pos_shaiba = sh_collider.getAbsolutePosition();
        shaiba_velocity = current_pos_shaiba.subtract(prev_pos_shaiba);

        frames = 0;
        // }
      }
    }
  }
};
let attackers_added = [false, false, false];
let attackers_timers = [null, null, null];

let count_shaibas_plus_timer = null;

// webapp_launcher()

let first_req_score = false;
function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  console.log("Query variable %s not found", variable);
}
const Game = () => {
  const getPrevScore = async () => {
    if (getQueryVariable("user_id") !== undefined) {
      try {
        /**
         * user_id - id пользователя
score - результат игры
token - соль, которая состоит из пароля, id и результата (sha256)
         */
        let hash = sha256.create();
        const token_string = "7%XC{E{CNZwm" + getQueryVariable("user_id");
        hash.update(token_string);
        const token = hash.hex();
        const response = await axios.get(
          "https://api.game-api.webtm.ru/get_score?user_id=" +
            getQueryVariable("user_id") +
            "&token=" +
            token
        );
        console.log(response.data);
        setPrevScore(response.data.last_score);
      } catch (error) {
        console.log(error);
      }
    }
  };
  if (first_req_score === false) {
    getPrevScore();
    first_req_score = true;
  }
  const [disabled, setDisabled] = useState(true);
  const [playerKicked, setPplayerKicked] = useState(false);
  const [instructions_see, setInstructions_see] = useState(false);
  const [start_visible, setStart_visible] = useState(true);
  const [loading_back_visible, setLoading_back_visible] = useState(true);
  const [pausedState, setPaused] = useState(false);
  const [top_bar_visible, setTop_bar_visible] = useState(false);
  const [scoreState, setScore] = useState(0);
  const [levelState, setLevel] = useState(0);
  const [goalsState, setGoals] = useState(0);
  const [newLevelState, setNewLevelState] = useState(false);
  const [prevScoreState, setPrevScore] = useState(0);
  const [gameOverState, setGameOver] = useState(false);
  const [countShaibasState, setCountShaibas] = useState(7);
  const [powerState, setPower] = useState(0);
  const [shaibaPlusState, setShaibaPlus] = useState(false);

  const setPower_ = (power_new_state) => {
    setPower(power_new_state);
    power = power_new_state;
  };

  const save_result = async () => {
    if (getQueryVariable("user_id") !== undefined) {
      try {
        /**
         * user_id - id пользователя
score - результат игры
token - соль, которая состоит из пароля, id и результата (sha256)
         */
        let hash = sha256.create();
        const token_string =
          "7%XC{E{CNZwm" + getQueryVariable("user_id") + score;
        hash.update(token_string);
        const token = hash.hex();
        const response = await axios.post(
          "https://api.game-api.webtm.ru/new_score?user_id=" +
            getQueryVariable("user_id") +
            "&token=" +
            token +
            "&score=" +
            score,
          {
            // user_id: getQueryVariable("user_id"),
            // score,
            // token,
          }
        );
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    /**
     * Условно пароль
Qwerty123
Пользователь с id
100000
И результат 10
Получаем Qwerty12310000010 и это в хеш
     */
  };

  const setCountShaibas_ = useCallback((count_new_state) => {
    if (count_new_state > shaiba_count) {
      if (count_shaibas_plus_timer) {
        clearTimeout(count_shaibas_plus_timer);
      }
      setShaibaPlus(true);
      count_shaibas_plus_timer = setTimeout(() => {
        setShaibaPlus(false);
      }, 1200);
    }
    if (count_new_state === 0) {
      setCountShaibas(count_new_state);
      shaiba_count = count_new_state;

      setGameOver(true);
      paused = true;
      save_result();
      setPrevScore(score);

      game_started = false;
    } else {
      if (count_new_state > 7) {
      } else {
        setCountShaibas(count_new_state);
        shaiba_count = count_new_state;
      }
    }
  }, []);

  const setLevel_ = useCallback((level_new_state) => {
    setLevel(level_new_state);
    level = level_new_state;
  }, []);

  const setShtanga_ = () => {
    // const new_score_state = score + scores_table[levelState].shtanga;
    // score = new_score_state;
    // setScore(new_score_state);
  };

  function setAttackerKlushka(i) {
    if (!attackers_klushkas[i]) {
      const attacker_klushka = MeshBuilder.CreateCylinder(
        "klushka",
        {},
        scene_global
      );
      attackers_klushkas[i] = attacker_klushka;

      attacker_klushka.scaling = new Vector3(0.1, 0.2, 0.45);
      attacker_klushka.position = new Vector3(1, 0.1, 15);

      // attacker_klushka.setParent(attacker.meshes[0], true, true);

      // attacker.meshes[0].position = new Vector3(4, 0.1, 1.5);
      attacker_klushka.isVisible = false;
      // attacker_klushka.isReady = false;
      // attackers_klushkas.push(attacker_klushka);
      attackers_klushkas[i].actionManager = new ActionManager(scene_global);
      attackers_klushkas[i].actionManager.registerAction(
        new ExecuteCodeAction(
          {
            trigger: ActionManager.OnIntersectionEnterTrigger,
            parameter: sh_collider,
          },
          () => {
            console.log("kicked by attacker");
            // console.log("damir privet");

            console.log("sh_collider", shaiba.meshes[0].getAbsolutePosition());
            console.log(
              "attacker_klushka",
              attacker_klushka.getAbsolutePosition()
            );

            let angle = calculateReboundAngleXZ(
              sh_collider.getAbsolutePosition(),
              attacker_klushka.getAbsolutePosition()
            );
            global_speed += 4;
            console.log("angle", angle);
            reboundAngle = angle;
            // xDirection = 1;

            let time = 1500;
            if (!shaiba_timer) {
              shaiba_timer = setTimeout(() => {
                shaiba_invisible = true;
                plane_miss.isVisible = true;
                setCountShaibas_(shaiba_count - 1);
              }, time - 300);
            }
            if (!timer && !timer_rounded) {
              timer = setTimeout(() => {
                player_kicked = false;
                plane_miss.isVisible = false;

                sh_collider.position = new Vector3(0, 0, sh_collider_z_start);
                shaiba_invisible = false;
                clearTimeout(shaiba_timer);
                shaiba_timer = null;
                clearTimeout(timer_rounded);
                timer = null;
                timer_rounded = null;
              }, time);
            }
          }
        )
      );
      // attacker_cylinder.setParent(attacker.meshes[0], true, true);
    }
  }
  function setAttackers() {
    if (table_levels[level].attackers.count > 0) {
      console.log("attackers count", table_levels[level].attackers.count);
      for (let i = 0; i < table_levels[level].attackers.count; i++) {
        if (attackers_added[i] === false) {
          let x_first_pos = rand(
            ranges_attackers[i].x[0],
            ranges_attackers[i].x[1]
          );
          let z_first_pos = rand(
            ranges_attackers[i].z[0],
            ranges_attackers[i].z[1]
          );

          attackers_targets[i].x = rand(
            ranges_attackers[i].x[0],
            ranges_attackers[i].x[1]
          );
          attackers_targets[i].z = rand(
            ranges_attackers[i].z[0],
            ranges_attackers[i].z[1]
          );

          attackers_timers[i] = setInterval(() => {
            attackers_targets[i].x = rand(
              ranges_attackers[i].x[0],
              ranges_attackers[i].x[1]
            );
            attackers_targets[i].z = rand(
              ranges_attackers[i].z[0],
              ranges_attackers[i].z[1]
            );
            // console.log("attacker_target_z", attacker_target_z);
          }, table_levels[level].attackers.timer);
          if (i === 0) {
            attacker_container.addAllToScene();
            // attacker_container.meshes[0].position.set(1,0.1,1)
            attacker_container.meshes[0].position = new Vector3(
              x_first_pos,
              0.1,
              z_first_pos
            );
            setAttackerKlushka(i);
            attackers_klushkas[i].setParent(attacker_container.meshes[0]);
            attackers_klushkas[i].position = new Vector3(0.2, 0.1, 1);
            //      attacker_klushka.position = new Vector3(0.2, 0.1, 1);

            // attacker_container.meshes[0].rotation = new Vector3(

            // )
          } else {
            //  let attacker1 = attacker_container.instantiateModelsToScene()
            // let entries = attacker_container.instantiateModelsToScene();
            // entries.rootNodes[0].isVisible = false;
            // //  let i = 0
            // entries.rootNodes[0].position = new Vector3(0, 0.1, 1.5);
            // entries.rootNodes[0].rotation = new Vector3(0, (180 * Math.PI) / 180, 0);
            // entries.animationGroups[0].play(true);
            //  for (var node of entries.rootNodes) {
            //      node.position.x += 1;
            //  }
            let new_attacker = attacker_container.instantiateModelsToScene();
            new_attacker.rootNodes[0].position = new Vector3(
              x_first_pos,
              0.1,
              z_first_pos
            );
            attackers_timers[i] = setInterval(() => {
              attackers_targets[i].x = rand(
                ranges_attackers[i].x[0],
                ranges_attackers[i].x[1]
              );
              attackers_targets[i].z = rand(
                ranges_attackers[i].z[0],
                ranges_attackers[i].z[1]
              );
            }, table_levels[level].attackers.timer);
            attackers.push(new_attacker);
            // attackers

            setAttackerKlushka(i);
            attackers_klushkas[i].setParent(new_attacker.rootNodes[0]);
            attackers_klushkas[i].position = new Vector3(0.2, 0.1, 1);
          }
          attackers_added[i] = true;
          attackers_klushkas[i].isVisible = false;
        } else {
          let prev_timer_delay = table_levels[level - 1].attackers.timer;
          let new_timer_delay = table_levels[level].attackers.timer;

          if (prev_timer_delay !== new_timer_delay) {
            clearInterval(attackers_timers[i]);
            attackers_timers[i] = null;
            attackers_timers[i] = setInterval(() => {
              attackers_targets[i].x = rand(
                ranges_attackers[i].x[0],
                ranges_attackers[i].x[1]
              );
              attackers_targets[i].z = rand(
                ranges_attackers[i].z[0],
                ranges_attackers[i].z[1]
              );
            }, table_levels[level].attackers.timer);
          }
        }
      }
    }
  }
  const setGoals_ = () => {
    const new_goals_state = goals + 1;
    goals = new_goals_state;
    setGoals(new_goals_state);
    const new_score_state = score + scores_table[level].goal;
    score = new_score_state;
    setScore(new_score_state);
    if (score >= 900) {
      setGameOver(true);
      paused = true;

      save_result();
      setPrevScore(score);

      game_started = false;
    } else {
      if (goals % goals_by_level === 0) {
        console.log("new goal");
        if (level < 7) {
          console.log("new level", level + 1);
          const new_level_state = level + 1;
          level = new_level_state;
          setLevel(new_level_state);
          setAttackers();
          setNewLevelState(true);
        }
      } else {
        setNewLevelState(false);
      }
    }
  };

  const new_game_start = () => {
    paused = false;
    console.log(attackers_klushkas);

    for (let i = 0; i < attackers_klushkas.length; i++) {
      if (attackers_klushkas[i] !== null) {
        attackers_klushkas[i].setParent(null);
        attackers_klushkas[i].position = new Vector3(1, 0.1, 15);
      }
    }
    attacker_container.removeAllFromScene();

    for (let i = 0; i < attackers.length; i++) {
      // const local_attacker = attackers[i];
      attackers[i].dispose();
      // attackers[i] = null
      // attackers.shift()
    }

    for (let i = 0; i < attackers_added.length; i++) {
      attackers_added[i] = false;
    }
    for (let i = 0; i < attackers_timers; i++) {
      clearInterval(attackers_timers[i]);
      attackers_timers[i] = null;
    }

    console.log(attackers_klushkas);

    attackers = [];
    console.log(attackers);

    // attackers
    game_started = true;
    setGameOver(false);

    setGoals(0);
    setScore(0);
    setLevel(0);
    setCountShaibas(7);
    shaiba_count = 7;
    level = 0;
    goals = 0;
    score = 0;
  };
  const setGameOver_ = () => {
    setGameOver(false);
  };

  // const setScore_ = useCallback((score_new_state) => {
  //   setScore(score_new_state);
  //   score = score_new_state;
  // }, []);

  const setPaused_ = useCallback((paused_new_state) => {
    setPaused(paused_new_state);
    paused = paused_new_state;
  }, []);
  const setDisabled_ = useCallback((disabled) => {
    setDisabled(disabled);
    global_disabled = disabled;
  }, []);

  const callback = useCallback(
    onSceneReady({
      setLoading_back_visible,
      setDisabled,
      setGoals_,
      setScore,
      setPaused_,
      setShtanga_,
      setPower,
      setCountShaibas_,
    }),
    []
  );
  /**
   * Если сделать setDisabled_ вместо setDisabled, то два раза перезназначать не надо, будет изменена и глобальная переменнная
   * и реакт стейт
   */
  //  const callback = useCallback(onSceneReady(setDisabled_, setPplayerKicked), []);

  return (
    <>
      <div>
        <UI
          disabled={disabled}
          setDisabled={setDisabled_}
          playerKicked={playerKicked}
          newLevelState={newLevelState}
          levelState={levelState}
          setGoals_={setGoals_}
          countShaibasState={countShaibasState}
          shaibaPlusState={shaibaPlusState}
        />
        <UI_instructions
          setInstructions_see={setInstructions_see}
          instructions_see={instructions_see}
          setTop_bar_visible={setTop_bar_visible}
        />
        <UI_back_start_blacked
          setInstructions_see={setInstructions_see}
          start_visible={start_visible}
          setStart_visible={setStart_visible}
          new_game_start={new_game_start}
          prevScoreState={prevScoreState}
        />
        <UI_loading_back
          setLoading_back_visible={setLoading_back_visible}
          loading_back_visible={loading_back_visible}
        />
        <UI_pause_back_text pausedState={pausedState} setPaused={setPaused_} />
        <UI_top_bar
          pausedState={pausedState}
          setPaused={setPaused_}
          top_bar_visible={top_bar_visible}
          scoreState={scoreState}
          levelState={levelState}
        />
        <UI_game_over
          setStart_visible={setStart_visible}
          gameOverState={gameOverState}
          setGameOver_={setGameOver_}
          prevScoreState={prevScoreState}
          new_game_start={new_game_start}
          save_result={save_result}
        />
        <UI_power power={powerState} />
      </div>
      <SceneComponent
        antialias
        onSceneReady={callback}
        onRender={onRender}
        id="my-canvas"
      />
    </>
  );
};
export default Game;

const UI_power = ({ power }) => {
  let colors = [
    "#315fd2",
    "#366dd8",
    "#3b7adf",
    "#4289e6",

    "#4798ed",
    "#4ba3f4",
    "#4dabf2",
    "#4daaeb",

    "#4da8df",
    "#4ca7d8",
    //10
    "#4aa5cd",
    "#49a3c3",
    "#48a2bb",
    "#479fb0",
    "#469da6",
    //15

    "#4fa1a2",
    "#459a94",
    "#44988a",
    "#439780",
    "#429576",
    "#41936d",
    "#409161",
  ];
  let rows = [];
  for (let i = 0; i < 22; i++) {
    const color = i < power && flag_downed ? colors[i] : "white";
    const el = (
      <div
        style={{
          position: "absolute",
          top: `${550 - i * 15}px`,
          right: "20px",
          width: "10px",
          height: "10px",
          background: color,
          zIndex: "2000",
        }}
      ></div>
    );
    rows.push(el);
  }
  return <>{rows}</>;
};

const UI_top_bar = ({
  pausedState,
  setPaused,
  top_bar_visible,
  scoreState,
  levelState,
}) => {
  return (
    <>
      {top_bar_visible ? (
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            background: "black",
            height: "50px",
            color: "white",
            zIndex: "2000",
            width: "100vw",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "12px",
              left: "10px",
              height: "50px",
            }}
          >
            <span className="gotham">Очки: &nbsp;</span>
            <span className="avenir">
              <b>
                <i>{scoreState}</i>
              </b>
            </span>
          </div>
          <div
            style={{
              position: "absolute",
              top: "12px",
              left: "110px",
              right: "0",
              // margin: "auto",
            }}
          >
            <span className="gotham">Уровень: &nbsp;</span>
            <span className="avenir">
              <b>
                <i>{levelState + 1}</i>
              </b>
            </span>
          </div>
          {/* img вложенный в оберточный див дает позиционирование не от края экрана, а от края контейнера, видимо накладывается основная директива */}
          <div
            style={{
              position: "absolute",
              top: "6px",
              // right: "-1vw",
              height: "50px",
              right: "5px",
            }}
          >
            <img
              src={Pause_button}
              alt="logo"
              style={{ height: "35px", zIndex: "2006" }}
              onClick={() => setPaused(!pausedState)}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

const UI_instructions = ({
  instructions_see,
  setInstructions_see,
  setTop_bar_visible,
}) => {
  return (
    <>
      {instructions_see ? (
        <img
          src={Back_instructions}
          alt="logo"
          style={{
            position: "absolute",
            top: "10px",
            left: "0",
            right: "0",
            bottom: "0",
            margin: "auto",
            zIndex: "2002",
          }}
          className="adapt_media"
          onClick={() => {
            setInstructions_see(false);
            setTop_bar_visible(true);
          }}
        />
      ) : null}
    </>
  );
};
const UI_loading_back = ({
  loading_back_visible,
  setLoading_back_visible,
  setStart_visible,
}) => {
  return (
    <>
      {loading_back_visible ? (
        <>
          <img
            src={Loading_back}
            alt="logo"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              margin: "auto",
              zIndex: "2002",
              height: "100vh",
            }}
            className="adapt_media"
          />
          <img
            src={Loading_text}
            alt="logo"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              margin: "auto",
              zIndex: "2003",
            }}
            className="adapt_media"
          />
          <span
            className="loader"
            style={{
              position: "absolute",
              bottom: "40px",
              left: "0",
              right: "0",
              margin: "auto",
              zIndex: "2004",
            }}
          />
        </>
      ) : null}
    </>
  );
};

const UI_pause_back_text = ({ pausedState, setPaused }) => {
  return (
    <>
      {pausedState ? (
        <>
          <img
            src={Continue_button}
            alt="logo"
            style={{
              zIndex: "2005",
              top: "0",
              left: "0",
              right: "0",
              bottom: "100px",
              margin: "auto",
              position: "absolute",
            }}
            className="adapt_media"
            onClick={() => setPaused(false)}
          />
          <p
            className="gotham"
            style={{
              position: "absolute",
              top: "130px",
              left: "0",
              right: "0",
              margin: "auto",
              zIndex: "2002",
              fontSize: "45pt",
              color: "white",
              width: "100vw",
              textAlign: "center",
            }}
          >
            Пауза
          </p>
        </>
      ) : null}
    </>
  );
};
const UI_back_start_blacked = ({
  setInstructions_see,
  start_visible,
  setStart_visible,
  new_game_start,
  prevScoreState,
}) => {
  return (
    <>
      {start_visible ? (
        <>
          <img
            src={Back_start_blacked}
            alt="logo"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              margin: "auto",
              height: "100vh",

              zIndex: "2002",
            }}
            className="adapt_media"
          />
          {prevScoreState > 0 ? (
            <>
              <img
                src={Best_score_back}
                alt="logo"
                style={{
                  zIndex: "2002",
                  top: "0",
                  bottom: "15%",
                  left: "0",
                  right: "0",
                  margin: "auto",
                  position: "absolute",
                }}
                className="adapt_media"
              />
              <p
                className="gotham adapt_media"
                style={{
                  zIndex: "2002",
                  // paddingBottom: "150px",
                  left: "0",
                  right: "0",
                  margin: "auto",
                  bottom: "42%",
                  // top: "0",
                  position: "absolute",
                  fontSize: "16pt",
                  color: "white",
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                Вы набрали&nbsp;
                <span className="avenir">
                  <b>
                    <i>{prevScoreState}</i>
                  </b>
                </span>
                &nbsp;очков, начало новой игры приведет к потере текущего
                результата
              </p>
            </>
          ) : null}
          {/* <img
            src={Flowers}
            className="flowers adapt_media"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              margin: "auto",
              zIndex: "2002",
            }}
            alt="logo"
          /> */}
          <img
            src={Start_button}
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "-5vh",
              margin: "auto",
              zIndex: "2002",
            }}
            className="adapt_media"
            alt="logo"
            onClick={() => {
              setInstructions_see(true);
              setStart_visible(false);
              new_game_start();
            }}
          />
        </>
      ) : null}
    </>
  );
};

/**
 * 
            onClick={() => {
              setInstructions_see(true);
              setStart_visible(false);
              new_game_start();
            }}
 */
const UI_game_over = ({
  setStart_visible,
  setGameOver_,
  gameOverState,
  prevScoreState,
  new_game_start,
}) => {
  const click = () => {
    setStart_visible(true);
    setGameOver_(false);
  };
  return (
    <>
      {gameOverState ? (
        <>
          <img
            src={Game_over_back}
            alt="logo"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              margin: "auto",
              height: "100vh",
              zIndex: "2005",
            }}
            className="adapt_media"
            // onClick={() => {
            //   setStart_visible(true);
            //   setGameOver_(false);
            // }}
          />
          <p
            className="gotham"
            style={{
              position: "absolute",
              zIndex: "2006",
              fontSize: "5.5vh",
              color: "white",
              width: "100vw",
              textAlign: "center",
              top: "30vh",
              left: "0",
              right: "0",
              margin: "auto",
            }}
          >
            Игра окончена
          </p>
          <p
            className="gotham"
            style={{
              position: "absolute",
              zIndex: "2006",
              fontSize: "4vh",
              color: "white",
              width: "100vw",
              textAlign: "center",
              top: "45vh",
              left: "0",
              right: "0",
              margin: "auto",
            }}
            id="result"
          >
            Ты заработал <span className="avenir">{prevScoreState}</span> очков!
          </p>
          <img
            src={Save_result}
            style={{
              position: "absolute",
              bottom: "17vh",
              left: "0",
              right: "0",
              zIndex: "2006",
              margin: "auto",
            }}
            className="adapt_media"
            alt="logo"
            onClick={click}
          />
          <img
            src={Replay}
            style={{
              position: "absolute",
              bottom: "5vh",
              left: "0",
              right: "0",
              zIndex: "2006",
              margin: "auto",
            }}
            className="adapt_media"
            alt="logo"
            onClick={() => {
              setGameOver_(false);
              new_game_start();
            }}
          />
        </>
      ) : null}
    </>
  );
};
const UI = ({
  disabled,
  setDisabled,
  playerKicked,
  newLevelState,
  levelState,
  setGoals_,
  countShaibasState,
  shaibaPlusState,
}) => {
  useEffect(() => {
    console.log(playerKicked);
  });
  return (
    <>
      <img
        src={Shaiba_count}
        alt="logo"
        style={{
          position: "absolute",
          top: "50px",
          left: "-15px",
          // left: "-155px",
          right: "0",
          height: "85px",
          zIndex: "2000",
          // margin: "auto",
        }}
        onClick={() => {
          // setDisabled(!disabled);
          // setGoals_();
          // setNewLevelState(true);
        }}
      />
      {shaibaPlusState ? (
        <img
          src={Shaiba_plus}
          alt="logo"
          style={{
            zIndex: "2000",
            position: "absolute",
            top: "138px",
            left: "0",
            height: "50px",
          }}
        />
      ) : null}
      {/* <img src={Shaiba_plus} alt="logo" style={{ zIndex: "2000", position: "absolute", top: "130px", left: "0", height: "70px"}} /> */}
      <span
        className="avenir"
        style={{
          position: "absolute",
          top: "85px",
          left: "102px",
          height: "100px",
          color: "black",
          fontSize: "16px",
          fontWeight: "bold",
          zIndex: "2001",
          color: "white",
        }}
      >
        <b>
          <i>{countShaibasState}/7</i>
        </b>
      </span>
      {!disabled ? (
        <>
          <img
            src={Goal_back10}
            alt="logo"
            className="blink-image adapt_media"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              height: "100vh",
              margin: "auto",
              zIndex: "2000",
            }}
          />
          <img
            src={Goal_back11}
            alt="logo"
            className="blink-image1 adapt_media"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              height: "100vh",
              margin: "auto",
              zIndex: "2000",
            }}
          />
          <img
            src={Goal_back12}
            alt="logo"
            className="blink-image2 adapt_media"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              height: "100vh",
              margin: "auto",
              zIndex: "2000",
            }}
          />
          {newLevelState ? (
            <p
              className="gotham text-stroke"
              style={{
                position: "absolute",
                top: "200px",
                left: "0",
                right: "0",
                margin: "auto",
                zIndex: "2002",
                fontSize: "31pt",
                color: "white",
                width: "100vw",
                textAlign: "center",
              }}
            >
              Новый уровень {levelState + 1}
            </p>
          ) : null}
          <img
            src={Classic_goal}
            alt="logo"
            // className="adapt_media"
            style={{
              position: "absolute",
              top: "120px",
              left: "0",
              right: "0",
              height: "100px",
              margin: "auto",
              zIndex: "2000",
            }}
          />
        </>
      ) : null}
    </>
  );
};
