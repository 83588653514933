import { useEffect, useRef, useState } from "react";
import { Engine, Scene, PhysicsViewer, SceneOptimizer, SceneOptimizerOptions } from "@babylonjs/core";

const SceneComponent = ({
  antialias,
  engineOptions,
  adaptToDeviceRatio,
  sceneOptions,
  onRender,
  onSceneReady,
  ...rest
}) => {
  const reactCanvas = useRef(null);

  // set up basic engine and scene
  const [fps, setFps] = useState(0)
  useEffect(() => {
    const { current: canvas } = reactCanvas;

    if (!canvas) return;

    const engine = new Engine(
      canvas,
      antialias,
      engineOptions,
      // adaptToDeviceRatio
    );
    // engine.setSize(4096, 3000, true);
    const scene = new Scene(engine, sceneOptions);
    let options = new SceneOptimizerOptions(30, 50);
    // options.addOptimization(SceneOptimizerOptions.HighDegradationAllowed())
    const opt_str = options.optimizations
    console.log(JSON.stringify(opt_str))
    if (scene.isReady()) {
      SceneOptimizer.OptimizeAsync(scene, options);

      onSceneReady(scene);


    } else {
      scene.onReadyObservable.addOnce((scene) => {
        SceneOptimizer.OptimizeAsync(scene, options);

        onSceneReady(scene)

      });
    }
    // engine.setHardwareScalingLevel(1)
    engine.setHardwareScalingLevel((1 / window.devicePixelRatio) + 0.2)
    engine.runRenderLoop(() => {
      if (typeof onRender === "function") onRender(scene);
      engine.resize()
      scene.render();
      // engine.
      setFps(engine.getFps().toFixed() + " fps")

      // console.log(engine.getFps().toFixed() + " fps")
    });
    // setInterval(() => {
    //     if (typeof onRender === "function") onRender(scene);
    //     engine.resize()
    //     scene.render();
    //     setFps(engine.getFps().toFixed() + " fps")
  
    //     // console.log(engine.getFps().toFixed() + " fps")
    //   }, 120/1000)

    const resize = () => {
      // scene.getEngine().resize();
      // engine.setSize(window.innerWidth * 1.5, window.innerHeight * 1.5);
    };

    if (window) {
      window.addEventListener("resize", resize);
    }

    return () => {
      scene.getEngine().dispose();

      if (window) {
        window.removeEventListener("resize", resize);
      }
    };
  }, [
    antialias,
    engineOptions,
    adaptToDeviceRatio,
    sceneOptions,
    onRender,
    onSceneReady,
  ]);
  //  <div id="fps" style={{position: "absolute", top: 10, left: 10, color: "white"}}>{fps}</div>

  return (
  <div>
       <div id="fps" style={{position: "absolute", top: 50, left: 10, color: "white"}}>{fps}</div>

      <canvas ref={reactCanvas} {...rest}/>;
  </div>
  )
};
export default SceneComponent;
